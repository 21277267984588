import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderMedia id='152-bert' mdxType="HeaderMedia" />
    <hr></hr>
    <h1>{`Über uns`}</h1>
    <Wrapper width={960} style={{
      textAlign: 'justify'
    }} mdxType="Wrapper">
      <h2 {...{
        "id": "wer-sind-wir-und-was-machen-wir-konkret"
      }}>{`Wer sind wir und was machen wir konkret?`}</h2>
      <p>{`  Wir sind mit unserem Netzwerk erfahrene Immobilienexperten und seit Jahrzehnten im Geschäft.
Dazu gehören Bausachverständige, Architekten, pensionierte Behördenleiter und Immobilienmaklerprofis.
Wir verkaufen Grundstücke, Häuser, Freiflächen und Anlageimmobilien - schaffen Lösungen für Immobilienverkäufer und Investoren.
Wir entwickeln Grundstücke und schaffen Baurecht.`}</p>
      <h2 {...{
        "id": "was-bieten-wir-an"
      }}>{`Was bieten wir an?`}</h2>
      <h3 {...{
        "id": "fur-verkaufer"
      }}>{`Für Verkäufer:`}</h3>
      <p>{`  Wir verkaufen Ihr Haus, Ihr Grundstück, Ihre Freifläche – Ihren Hof, Ihre Firmenimmobilien.
Und das zeitnah.`}</p>
      <h3 {...{
        "id": "fur-investoren"
      }}>{`Für Investoren:`}</h3>
      <p>{`  Wir entwickeln Flächen, schaffen Baurecht und kümmern uns konkret um die baurechtlichen individuellen Möglichkeiten.
Wir projektieren Objekte – auch die bislang schwer zu entwickeln schienen.`}</p>
      <p>{`  Gerade Herausforderungen spornen uns an!`}</p>
      <h2 {...{
        "id": "ihre-vorteile-als-unsere-kunden"
      }}>{`Ihre Vorteile als unsere Kunden!`}</h2>
      <p>{`  Wir sind für Sie aktiv, energisch, zuverlässig, kompetent und schnell – sodaß Sie als Kunde Zeit sparen und ein Maximum an Ertrag und Handlungsfreiheiten für Ihre Investitionen und Pläne erhalten.`}</p>
      <p>{`  Sprechen Sie uns an – wir freuen uns auf Sie!`}</p>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      